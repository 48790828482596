import _objectSpread from "D:/fsm/acro-harvest/frontend/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.function.name.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import VisitListingTemplate from "@/view/pages/visit/Visit-Listing-Template";
export default {
  name: "visit",
  mounted: function mounted() {
    var route_name = this.$route.name;
    var indexd = route_name.indexOf("admin");

    if (indexd >= 0) {
      this.isAdmin = true;
    }

    this.$store.dispatch(SET_BREADCRUMB, [{
      title: "Visit"
    }]);
  },
  data: function data() {
    return {
      isAdmin: false,
      pageLoading: false,
      // visitTab: null,
      counts: {
        all: 0,
        overdue: 0,
        today: 0,
        tomorrow: 0,
        this_week: 0,
        upcomming: 0,
        completed: 0
      }
    };
  },
  methods: {
    getVisitCounts: function getVisitCounts(countObject) {
      var _this = this;

      if (_this.lodash.isEmpty(countObject) === false) {
        _this.counts = {
          all: _this.lodash.toSafeInteger(countObject.all),
          overdue: _this.lodash.toSafeInteger(countObject.overdue),
          today: _this.lodash.toSafeInteger(countObject.today),
          tomorrow: _this.lodash.toSafeInteger(countObject.tomorrow),
          this_week: _this.lodash.toSafeInteger(countObject.thisWeek),
          upcomming: _this.lodash.toSafeInteger(countObject.upcomming),
          completed: _this.lodash.toSafeInteger(countObject.completed)
        };
      }
    }
  },
  components: {
    VisitListingTemplate: VisitListingTemplate
  },
  created: function created() {
    if (this.$route.query && this.$route.query.tab) {
      this.visitTab = this.$route.query.tab;
    }
  },
  computed: {
    visitTab: {
      set: function set(val) {
        var query = _objectSpread({}, this.$route.query);

        query.tab = val;

        if (val != this.visitTab) {
          this.$router.push({
            query: query
          });
        }
      },
      get: function get() {
        return this.$route.query.tab || "all";
      }
    }
  }
};